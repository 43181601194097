import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default function IndexPage(): JSX.Element {
  return (
    <Layout>
      <div id="Home">
        <div className="bg">
          <StaticImage
            src="../images/banners/HomePage.png"
            alt=""
            placeholder="dominantColor"
            objectFit="cover"
            layout="constrained"
            style={{ display: 'inline-block', position: 'static' }}
            loading="eager"
          />
        </div>

        <div className="text">
          <h1 className="title">Learn and Share about Genetics</h1>

          <div className="h2Div">
            <Link to="/careers">
              <h2>Careers in Genetics,</h2>
            </Link>
            <Link to="/conversation">
              <h2>Start a Conversation,</h2>
            </Link>
            <Link to="/basics">
              <h2>Learn about Genetics.</h2>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
